/* @tailwind base;
@tailwind utilities;
@tailwind components; */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  font-family: 'Inter', sans-serif !important;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.hide-scroll::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hover-scroll::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}
.hover-scroll::-webkit-scrollbar:hover {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
  display: auto;
}
.hover-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll:hover {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  margin-left:50px;
  margin-top:15px;
  margin-bottom:15px;
  position: relative;
  box-sizing: border-box;
  animation: animloader 4s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  12% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  24% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  36% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  48% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  60% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  72% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  84% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -109px 25.3333333333px #ffdd00, -208px 82.3333333333px #00aaff, 95px -133.6666666667px #ae00ff, -13px -403.6666666667px #ff6a00, 222px -166.6666666667px #00ffe1, 78px 47.3333333333px #ff00ae, -75px -207.6666666667px #ff6600, -20px 46.3333333333px #62ff00, 50px -131.6666666667px #00ffe6, 18px -372.6666666667px #ffcc00, 162px -312.6666666667px #ffdd00, -1px -230.6666666667px #fff700, -160px 14.3333333333px #77ff00, -9px -60.6666666667px #ff00a2, -115px -152.6666666667px fuchsia, 178px -56.6666666667px #ff9900, -219px -115.6666666667px #ff0051, 60px -318.6666666667px #00ff11, -17px -68.6666666667px #005eff, 110px -403.6666666667px #00ff6f, -120px -31.6666666667px #ff9500, 159px -176.6666666667px #fbff00, -14px -391.6666666667px #ddff00, -154px -158.6666666667px #6f00ff, -197px -403.6666666667px #ff4d00, 183px -149.6666666667px #3700ff, 215px 49.3333333333px #bfff00, 181px -385.6666666667px #00ff73, 216px -8.6666666667px #8800ff, -237px -284.6666666667px #ff0037, 110px -36.6666666667px #9500ff, 230px -373.6666666667px #99ff00, 234px -162.6666666667px #2200ff, -238px 4.3333333333px #00ffa6, -196px -154.6666666667px #00ffaa, -44px -306.6666666667px #f700ff, -159px -28.6666666667px #95ff00, 202px -137.6666666667px #0080ff, -56px -133.6666666667px #00ff11, -110px -130.6666666667px #22ff00, 226px -104.6666666667px #ee00ff, 102px -222.6666666667px #1500ff, 63px -12.6666666667px #00ffbb, -148px -351.6666666667px #ff00ae, -86px -135.6666666667px #ff0051, 149px -231.6666666667px #ff3300, 74px 65.3333333333px #00ff99, -90px -78.6666666667px #d5ff00, -198px -212.6666666667px #ff00b7, 179px -72.6666666667px #ff1a00, 146px -298.6666666667px #0400ff;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -109px 25.3333333333px #ffdd00, -208px 82.3333333333px #00aaff, 95px -133.6666666667px #ae00ff, -13px -403.6666666667px #ff6a00, 222px -166.6666666667px #00ffe1, 78px 47.3333333333px #ff00ae, -75px -207.6666666667px #ff6600, -20px 46.3333333333px #62ff00, 50px -131.6666666667px #00ffe6, 18px -372.6666666667px #ffcc00, 162px -312.6666666667px #ffdd00, -1px -230.6666666667px #fff700, -160px 14.3333333333px #77ff00, -9px -60.6666666667px #ff00a2, -115px -152.6666666667px fuchsia, 178px -56.6666666667px #ff9900, -219px -115.6666666667px #ff0051, 60px -318.6666666667px #00ff11, -17px -68.6666666667px #005eff, 110px -403.6666666667px #00ff6f, -120px -31.6666666667px #ff9500, 159px -176.6666666667px #fbff00, -14px -391.6666666667px #ddff00, -154px -158.6666666667px #6f00ff, -197px -403.6666666667px #ff4d00, 183px -149.6666666667px #3700ff, 215px 49.3333333333px #bfff00, 181px -385.6666666667px #00ff73, 216px -8.6666666667px #8800ff, -237px -284.6666666667px #ff0037, 110px -36.6666666667px #9500ff, 230px -373.6666666667px #99ff00, 234px -162.6666666667px #2200ff, -238px 4.3333333333px #00ffa6, -196px -154.6666666667px #00ffaa, -44px -306.6666666667px #f700ff, -159px -28.6666666667px #95ff00, 202px -137.6666666667px #0080ff, -56px -133.6666666667px #00ff11, -110px -130.6666666667px #22ff00, 226px -104.6666666667px #ee00ff, 102px -222.6666666667px #1500ff, 63px -12.6666666667px #00ffbb, -148px -351.6666666667px #ff00ae, -86px -135.6666666667px #ff0051, 149px -231.6666666667px #ff3300, 74px 65.3333333333px #00ff99, -90px -78.6666666667px #d5ff00, -198px -212.6666666667px #ff00b7, 179px -72.6666666667px #ff1a00, 146px -298.6666666667px #0400ff;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -109px 25.3333333333px #ffdd00, -208px 82.3333333333px #00aaff, 95px -133.6666666667px #ae00ff, -13px -403.6666666667px #ff6a00, 222px -166.6666666667px #00ffe1, 78px 47.3333333333px #ff00ae, -75px -207.6666666667px #ff6600, -20px 46.3333333333px #62ff00, 50px -131.6666666667px #00ffe6, 18px -372.6666666667px #ffcc00, 162px -312.6666666667px #ffdd00, -1px -230.6666666667px #fff700, -160px 14.3333333333px #77ff00, -9px -60.6666666667px #ff00a2, -115px -152.6666666667px fuchsia, 178px -56.6666666667px #ff9900, -219px -115.6666666667px #ff0051, 60px -318.6666666667px #00ff11, -17px -68.6666666667px #005eff, 110px -403.6666666667px #00ff6f, -120px -31.6666666667px #ff9500, 159px -176.6666666667px #fbff00, -14px -391.6666666667px #ddff00, -154px -158.6666666667px #6f00ff, -197px -403.6666666667px #ff4d00, 183px -149.6666666667px #3700ff, 215px 49.3333333333px #bfff00, 181px -385.6666666667px #00ff73, 216px -8.6666666667px #8800ff, -237px -284.6666666667px #ff0037, 110px -36.6666666667px #9500ff, 230px -373.6666666667px #99ff00, 234px -162.6666666667px #2200ff, -238px 4.3333333333px #00ffa6, -196px -154.6666666667px #00ffaa, -44px -306.6666666667px #f700ff, -159px -28.6666666667px #95ff00, 202px -137.6666666667px #0080ff, -56px -133.6666666667px #00ff11, -110px -130.6666666667px #22ff00, 226px -104.6666666667px #ee00ff, 102px -222.6666666667px #1500ff, 63px -12.6666666667px #00ffbb, -148px -351.6666666667px #ff00ae, -86px -135.6666666667px #ff0051, 149px -231.6666666667px #ff3300, 74px 65.3333333333px #00ff99, -90px -78.6666666667px #d5ff00, -198px -212.6666666667px #ff00b7, 179px -72.6666666667px #ff1a00, 146px -298.6666666667px #0400ff;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -109px 25.3333333333px #ffdd00, -208px 82.3333333333px #00aaff, 95px -133.6666666667px #ae00ff, -13px -403.6666666667px #ff6a00, 222px -166.6666666667px #00ffe1, 78px 47.3333333333px #ff00ae, -75px -207.6666666667px #ff6600, -20px 46.3333333333px #62ff00, 50px -131.6666666667px #00ffe6, 18px -372.6666666667px #ffcc00, 162px -312.6666666667px #ffdd00, -1px -230.6666666667px #fff700, -160px 14.3333333333px #77ff00, -9px -60.6666666667px #ff00a2, -115px -152.6666666667px fuchsia, 178px -56.6666666667px #ff9900, -219px -115.6666666667px #ff0051, 60px -318.6666666667px #00ff11, -17px -68.6666666667px #005eff, 110px -403.6666666667px #00ff6f, -120px -31.6666666667px #ff9500, 159px -176.6666666667px #fbff00, -14px -391.6666666667px #ddff00, -154px -158.6666666667px #6f00ff, -197px -403.6666666667px #ff4d00, 183px -149.6666666667px #3700ff, 215px 49.3333333333px #bfff00, 181px -385.6666666667px #00ff73, 216px -8.6666666667px #8800ff, -237px -284.6666666667px #ff0037, 110px -36.6666666667px #9500ff, 230px -373.6666666667px #99ff00, 234px -162.6666666667px #2200ff, -238px 4.3333333333px #00ffa6, -196px -154.6666666667px #00ffaa, -44px -306.6666666667px #f700ff, -159px -28.6666666667px #95ff00, 202px -137.6666666667px #0080ff, -56px -133.6666666667px #00ff11, -110px -130.6666666667px #22ff00, 226px -104.6666666667px #ee00ff, 102px -222.6666666667px #1500ff, 63px -12.6666666667px #00ffbb, -148px -351.6666666667px #ff00ae, -86px -135.6666666667px #ff0051, 149px -231.6666666667px #ff3300, 74px 65.3333333333px #00ff99, -90px -78.6666666667px #d5ff00, -198px -212.6666666667px #ff00b7, 179px -72.6666666667px #ff1a00, 146px -298.6666666667px #0400ff;
  }
}
@keyframes bang {
  to {
    box-shadow: -109px 25.3333333333px #ffdd00, -208px 82.3333333333px #00aaff, 95px -133.6666666667px #ae00ff, -13px -403.6666666667px #ff6a00, 222px -166.6666666667px #00ffe1, 78px 47.3333333333px #ff00ae, -75px -207.6666666667px #ff6600, -20px 46.3333333333px #62ff00, 50px -131.6666666667px #00ffe6, 18px -372.6666666667px #ffcc00, 162px -312.6666666667px #ffdd00, -1px -230.6666666667px #fff700, -160px 14.3333333333px #77ff00, -9px -60.6666666667px #ff00a2, -115px -152.6666666667px fuchsia, 178px -56.6666666667px #ff9900, -219px -115.6666666667px #ff0051, 60px -318.6666666667px #00ff11, -17px -68.6666666667px #005eff, 110px -403.6666666667px #00ff6f, -120px -31.6666666667px #ff9500, 159px -176.6666666667px #fbff00, -14px -391.6666666667px #ddff00, -154px -158.6666666667px #6f00ff, -197px -403.6666666667px #ff4d00, 183px -149.6666666667px #3700ff, 215px 49.3333333333px #bfff00, 181px -385.6666666667px #00ff73, 216px -8.6666666667px #8800ff, -237px -284.6666666667px #ff0037, 110px -36.6666666667px #9500ff, 230px -373.6666666667px #99ff00, 234px -162.6666666667px #2200ff, -238px 4.3333333333px #00ffa6, -196px -154.6666666667px #00ffaa, -44px -306.6666666667px #f700ff, -159px -28.6666666667px #95ff00, 202px -137.6666666667px #0080ff, -56px -133.6666666667px #00ff11, -110px -130.6666666667px #22ff00, 226px -104.6666666667px #ee00ff, 102px -222.6666666667px #1500ff, 63px -12.6666666667px #00ffbb, -148px -351.6666666667px #ff00ae, -86px -135.6666666667px #ff0051, 149px -231.6666666667px #ff3300, 74px 65.3333333333px #00ff99, -90px -78.6666666667px #d5ff00, -198px -212.6666666667px #ff00b7, 179px -72.6666666667px #ff1a00, 146px -298.6666666667px #0400ff;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}